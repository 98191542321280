import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Pagination,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

function addOneMonths(date) {
  let tempDate = new Date(date);
  let d = tempDate.getDate();
  tempDate.setMonth(tempDate.getMonth() + 1);
  if (tempDate.getDate() != d) {
    tempDate.setDate(0);
  }
  return tempDate;
}

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const TambahJualBekas = () => {
  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "13px",
  };
  const { user, setting, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  // Data Register/Pembeli
  const [registers, setRegisters] = useState([]);
  const [noRegister, setNoRegister] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [almKantor, setAlmKantor] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [noKtpRegister, setNoKtpRegister] = useState("");
  const [noKKRegister, setNoKKRegister] = useState("");
  const [namaPjmRegister, setNamaPjmRegister] = useState("");
  const [noKtpPjmRegister, setNoKtpPjmRegister] = useState("");
  const [linkMapsRegister, setLinkMapsRegister] = useState("");
  const [linkMapsRegister2, setLinkMapsRegister2] = useState("");
  const [linkMapsRegister3, setLinkMapsRegister3] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [almRefRegister, setAlmRefRegister] = useState("");
  const [tlpRefRegister, setTlpRefRegister] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodePekerjaan, setKodePekerjaan] = useState("");
  const [ketPekerjaan, setKetPekerjaan] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [kodeLeasing, setKodeLeasing] = useState("");
  const [marketings, setMarketings] = useState([]);
  const [surveyors, setSurveyors] = useState([]);
  const [pekerjaans, setPekerjaans] = useState([]);
  const [kecamatans, setKecamatans] = useState([]);
  const [leasings, setLeasings] = useState([]);

  // Data Motor -> Dari Stok
  const [noRangka, setNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [nopol, setNopol] = useState("");
  const [tipe, setTipe] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaStnk, setNamaStnk] = useState("");
  const [noBpkb, setNoBpkb] = useState("");
  const [stoks, setStoks] = useState([]);

  // Data Penjualan -> dari input
  const [hargaTunai, setHargaTunai] = useState("");
  const [uangMuka, setUangMuka] = useState("");
  const [sisaPiutang, setSisaPiutang] = useState("");
  const [angPerBulan, setAngPerBulan] = useState("");
  const [tenor, setTenor] = useState("");
  const [bunga, setBunga] = useState("");
  const [jumlahPiutang, setJumlahPiutang] = useState("");
  const [noJual, setNoJual] = useState("");

  let tempPeriodeAwal = new Date(user.periode.periodeAwal);

  const [minDate, setMinDate] = useState(tempPeriodeAwal);
  const [noKwitansi, setNoKwitansi] = useState(user.kodeKwitansi);
  const [noTerakhir, setNoTerakhir] = useState(user.noTerakhir);
  const [inputTglJual, setInputTglJual] = useState(tempPeriodeAwal);
  const [jenisJual, setJenisJual] = useState("");
  const [inputTglAng, setInputTglAng] = useState(tempPeriodeAwal);
  const [tglAngAkhirFormatted, setTglAngAkhirFormatted] = useState("");
  const [tglAngAkhir, setTglAngAkhir] = useState("");

  const [kodeCOA, setKodeCOA] = useState(user.coaKasir);
  const [openCOA, setOpenCOA] = useState(false);
  const [searchTermCOA, setSearchTermCOA] = useState("");
  const [COAsData, setCOAsData] = useState([]);
  const [openJual, setOpenJual] = useState(false);
  const [searchTermJual, setSearchTermJual] = useState("");
  const [jualsData, setJualsData] = useState([]);

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTermRegister, setSearchTermRegister] = useState("");
  const [openRegister, setOpenRegister] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermRegister(query);
  };

  const handleClickOpenRegister = () => {
    setOpenRegister(true);
  };

  const handleCloseRegister = () => {
    setOpenRegister(false);
  };

  const handleClickOpenCOA = () => {
    setOpenCOA(true);
  };

  const handleCloseCOA = () => {
    setOpenCOA(false);
  };

  const handleClickOpenJual = () => {
    setOpenJual(true);
  };

  const handleCloseJual = () => {
    setOpenJual(false);
  };

  const handleClickOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const jenisJualOption = [{ label: "KREDIT" }, { label: "TUNAI" }];

  const marketingOptions = marketings.map((marketing) => ({
    label: `${marketing.kodeMarketing} - ${marketing.namaMarketing}`,
  }));

  const surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  const pekerjaanOptions = pekerjaans.map((pekerjaan) => ({
    label: `${pekerjaan.kodePekerjaan} - ${pekerjaan.namaPekerjaan}`,
  }));

  const kecamatanOptions = kecamatans.map((kecamatan) => ({
    label: `${kecamatan.kodeKecamatan} - ${kecamatan.namaKecamatan}`,
  }));

  const leasingOptions = leasings.map((leasing) => ({
    label: `${leasing.kodeLeasing} - ${leasing.namaLeasing}`,
  }));

  const nopolOptions = stoks.map((stok) => ({
    label: `${stok.nopol}`,
  }));

  const tempPostsCOA = COAsData.filter((val) => {
    if (searchTermCOA === "") {
      return val;
    } else if (
      val.kodeCOA.toUpperCase().includes(searchTermCOA.toUpperCase()) ||
      val.namaCOA.toUpperCase().includes(searchTermCOA.toUpperCase())
    ) {
      return val;
    }
  });

  const tempPostsJual = jualsData.filter((val) => {
    if (searchTermJual === "") {
      return val;
    } else if (
      val.noJual.toUpperCase().includes(searchTermJual.toUpperCase()) ||
      val.tanggalJualFormatted
        .toUpperCase()
        .includes(searchTermJual.toUpperCase()) ||
      val.namaRegister.toUpperCase().includes(searchTermJual.toUpperCase()) ||
      val.kodeLeasing.kodeLeasing
        .toUpperCase()
        .includes(searchTermJual.toUpperCase()) ||
      val.kodeLeasing.namaLeasing
        .toUpperCase()
        .includes(searchTermJual.toUpperCase()) ||
      val.tipe.toUpperCase().includes(searchTermJual.toUpperCase())
    ) {
      return val;
    }
  });

  function addMonths(date, months) {
    let tempDate = new Date(date);
    let d = tempDate.getDate();
    tempDate.setMonth(tempDate.getMonth() + +months - 1);
    if (tempDate.getDate() != d) {
      tempDate.setDate(0);
    }
    return tempDate;
  }

  const countDateDuration = (e) => {
    var finalDate = addMonths(inputTglAng, parseInt(e.target.value));
    var calculatedDateResult =
      finalDate.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      (finalDate.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      finalDate.getFullYear();
    if (jenisJual === "KREDIT") {
      setTglAngAkhir(finalDate);
      setTglAngAkhirFormatted(calculatedDateResult);
    }
  };

  useEffect(() => {
    getJualsLast();
    getCOAsData();
    increaseNoKwt();
    findDefaultDate();
    getStok();
    if (user.tipeUser === "OWN") {
      getMarketingAllCabang();
      getSurveyorAll();
    } else {
      getMarketing();
      getSurveyor();
    }
    getPekerjaan();
    getKecamatan();
    getLeasing();
    setJenisJual("KREDIT");
  }, []);

  useEffect(() => {
    getRegister();
  }, [page, searchTermRegister]);

  const increaseNoKwt = async () => {
    const response = await axios.post(`${tempUrl}/findUser/${user._id}`, {
      id: user._id,
      token: user.token,
    });
    let tempNoTerakhir = response.data.noTerakhir;
    tempNoTerakhir++;
    setNoKwitansi(noKwitansi + tempNoTerakhir);
    setNoTerakhir(tempNoTerakhir);
  };

  const getJualsLast = async () => {
    setLoading(true);
    const jualsLast = await axios.post(`${tempUrl}/jualsLast`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setJualsData(jualsLast.data);
    setLoading(false);
  };

  const getCOAsData = async () => {
    setLoading(true);
    const allCOAs = await axios.post(`${tempUrl}/COAsKasBank`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setCOAsData(allCOAs.data);
    setLoading(false);
  };

  const findDefaultDate = async () => {
    let tempToday = new Date();
    let tempPeriodeAwal = user.periode.periodeAwal.split("-");
    let tempPeriodeAwalFormatted = new Date(
      tempPeriodeAwal[0] +
        "-" +
        tempPeriodeAwal[1].toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        tempPeriodeAwal[2].toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })
    ).setHours(0, 0, 0, 0);
    let tempPeriodeAkhir = new Date(user.periode.periodeAkhir).setHours(
      23,
      59,
      59
    );

    if (
      tempToday >= tempPeriodeAwalFormatted ||
      tempToday <= tempPeriodeAkhir
    ) {
      // Default Date Today
      if (user.tipeUser === "ADM") {
        setMinDate(new Date());
      }
      setInputTglJual(new Date(tempToday));
      setInputTglAng(addOneMonths(new Date()));
    }
  };

  const getStoksByNopol = async (nopol) => {
    const allDaftarStoksByNopol = await axios.post(
      `${tempUrl}/daftarStoksByNopol`,
      {
        nopol,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    if (allDaftarStoksByNopol.data) {
      setNoRangka(allDaftarStoksByNopol.data.noRangka);
      setNoMesin(allDaftarStoksByNopol.data.noMesin);
      setTipe(allDaftarStoksByNopol.data.tipe);
      setNamaWarna(allDaftarStoksByNopol.data.namaWarna);
      setTahun(allDaftarStoksByNopol.data.tahun);
      setNamaStnk(allDaftarStoksByNopol.data.namaStnk);
      setNoBpkb(allDaftarStoksByNopol.data.noBpkb);
      setNopol(nopol);
    }
  };

  const getStok = async () => {
    setLoading(true);
    const allDaftarStokHasNopol = await axios.post(
      `${tempUrl}/daftarStoksNopol`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setStoks(allDaftarStokHasNopol.data);
    setLoading(false);
  };

  const getRegister = async () => {
    setLoading(true);
    const allRegisters = await axios.post(
      `${tempUrl}/registersPagination?search_query=${searchTermRegister}&page=${page}&limit=${limit}`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setQuery(searchTermRegister);
    setRegisters(allRegisters.data.registers);
    setPage(allRegisters.data.page);
    setPages(allRegisters.data.totalPage);
    setRows(allRegisters.data.totalRows);
    setLoading(false);
  };

  const getMarketing = async () => {
    setLoading(true);
    const allMarketings = await axios.post(`${tempUrl}/marketings`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setMarketings(allMarketings.data);
    setLoading(false);
  };

  const getMarketingAllCabang = async () => {
    setLoading(true);
    const allMarketings = await axios.post(`${tempUrl}/marketingsAllCabang`, {
      id: user._id,
      token: user.token,
    });
    setMarketings(allMarketings.data);
    setLoading(false);
  };

  const getSurveyor = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const getSurveyorAll = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyorsAllCabang`, {
      id: user._id,
      token: user.token,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const getPekerjaan = async () => {
    setLoading(true);
    const allPekerjaans = await axios.post(`${tempUrl}/pekerjaans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setPekerjaans(allPekerjaans.data);
    setLoading(false);
  };

  const getKecamatan = async () => {
    setLoading(true);
    const allKecamatans = await axios.post(`${tempUrl}/kecamatans`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKecamatans(allKecamatans.data);
    setLoading(false);
  };

  const getLeasing = async () => {
    setLoading(true);
    const allLeasings = await axios.post(`${tempUrl}/leasings`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setLeasings(allLeasings.data);
    setLoading(false);
  };

  const saveJual = async (e) => {
    e.preventDefault();
    setLoading(true);
    let isFailedValidation;
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    let newTglAngAkhir;
    let tempTglAngAkhir;
    if (tglAngAkhir) {
      newTglAngAkhir = new Date(tglAngAkhir);
      tempTglAngAkhir =
        newTglAngAkhir.getFullYear() +
        "-" +
        (newTglAngAkhir.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        newTglAngAkhir.getDate().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
    }

    let tglJual =
      inputTglJual?.getFullYear() +
      "-" +
      (inputTglJual?.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      inputTglJual?.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    let tglAng;
    if (inputTglAng) {
      tglAng =
        inputTglAng?.getFullYear() +
        "-" +
        (inputTglAng?.getMonth() + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        inputTglAng?.getDate().toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
    }

    let tempNoJual = await axios.post(`${tempUrl}/allJualByNoJual`, {
      noJual,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    if (tempNoJual.data.length > 0) {
      handleClickOpenAlert();
    } else {
      if (jenisJual === "KREDIT") {
        isFailedValidation =
          noJual.length === 0 ||
          tglJual.length === 0 ||
          jenisJual.length === 0 ||
          tglAng.length === 0 ||
          tglAngAkhir.length === 0 ||
          noRegister.length === 0 ||
          kodeMarketing.length === 0 ||
          kodeSurveyor.length === 0 ||
          kodePekerjaan.length === 0 ||
          kodeKecamatan.length === 0 ||
          kodeLeasing.length === 0 ||
          nopol.length === 0 ||
          hargaTunai.length === 0 ||
          uangMuka.length === 0 ||
          sisaPiutang.length === 0 ||
          angPerBulan.length === 0 ||
          tenor.length === 0 ||
          jumlahPiutang.length === 0 ||
          inputTglJual === null ||
          inputTglAng === null ||
          kodeCOA.length === 0;
      } else {
        isFailedValidation =
          noJual.length === 0 ||
          tglJual.length === 0 ||
          jenisJual.length === 0 ||
          noRegister.length === 0 ||
          kodeMarketing.length === 0 ||
          kodeSurveyor.length === 0 ||
          kodePekerjaan.length === 0 ||
          kodeKecamatan.length === 0 ||
          kodeLeasing.length === 0 ||
          nopol.length === 0 ||
          hargaTunai.length === 0 ||
          uangMuka.length === 0 ||
          sisaPiutang.length === 0 ||
          angPerBulan.length === 0 ||
          tenor.length === 0 ||
          jumlahPiutang.length === 0 ||
          inputTglJual === null ||
          kodeCOA.length === 0;
      }

      if (isFailedValidation) {
        setError(true);
        setOpen(!open);
      } else {
        try {
          const tempMarketing = await axios.post(`${tempUrl}/marketingByKode`, {
            kodeMarketing,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          });
          const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
            kodeSurveyor,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          });
          const tempPekerjaan = await axios.post(`${tempUrl}/pekerjaanByKode`, {
            kodePekerjaan: kodePekerjaan.split(" -", 1)[0],
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          });
          const tempKecamatan = await axios.post(`${tempUrl}/kecamatanByKode`, {
            kodeKecamatan: kodeKecamatan.split(" -", 1)[0],
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          });
          const tempLeasing = await axios.post(`${tempUrl}/leasingByKode`, {
            kodeLeasing,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          });
          let tempCOA = await axios.post(`${tempUrl}/COAByKode`, {
            kodeCOA,
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          });
          const tempStok = await axios.post(`${tempUrl}/daftarStoksByNopol`, {
            nopol,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          });
          if (jenisJual === "KREDIT") {
            // Save Jual Angsuran Customer Update Daftar Stok
            let savedJualAngsuranCustomerUpdateDaftarStok = await axios.post(
              `${tempUrl}/saveJualAngsuranCustomerUpdateDaftarStok`,
              {
                jualData: {
                  COA: tempCOA.data._id,
                  noRegister,
                  namaRegister,
                  almRegister,
                  almKantor,
                  tlpRegister,
                  noKtpRegister,
                  noKKRegister,
                  namaPjmRegister,
                  noKtpPjmRegister,
                  linkMapsRegister,
                  linkMapsRegister2,
                  linkMapsRegister3,
                  namaRefRegister,
                  almRefRegister,
                  tlpRefRegister,
                  kodeMarketing: tempMarketing.data._id,
                  kodeSurveyor: tempSurveyor.data._id,
                  kodeSurveyorAsli: tempSurveyor.data._id,
                  kodePekerjaan: tempPekerjaan.data._id,
                  ketPekerjaan,
                  kodeKecamatan: tempKecamatan.data._id,
                  kodeLeasing: tempLeasing.data._id,
                  noRangka,
                  noMesin,
                  nopol,
                  tipe,
                  namaWarna,
                  tahun,
                  namaStnk,
                  noBpkb,
                  hargaTunai: hargaTunai.replace(/,/g, ""),
                  uangMuka: uangMuka.replace(/,/g, ""),
                  sisaPiutang,
                  angPerBulan: angPerBulan.replace(/,/g, ""),
                  tenor,
                  bunga,
                  jumlahPiutang,
                  noJual,
                  noKwitansi,
                  tanggalJual: tglJual,
                  jenisJual,
                  tglAng,
                  tglAngAkhir: tempTglAngAkhir,
                  userInput: user.username,
                  angModal: sisaPiutang / tenor,
                  angBunga: angPerBulan.replace(/,/g, "") - sisaPiutang / tenor,
                  jenisBeli: "BEKAS",
                  tglInput: current_date,
                  jamInput: current_time,
                  userInput: user.username,
                  kodeCabang: user.cabang._id,
                  id: user._id,
                  token: user.token,
                },
                angsuranData: {
                  tenor,
                  noJual,
                  tglAng,
                  angModal: sisaPiutang / tenor,
                  angBunga: angPerBulan.replace(/,/g, "") - sisaPiutang / tenor,
                  angPerBulan: angPerBulan.replace(/,/g, ""),
                  tglInput: current_date,
                  jamInput: current_time,
                  userInput: user.username,
                  kodeCabang: user.cabang._id,
                  id: user._id,
                  token: user.token,
                },
                customerData: {
                  noRegister,
                  namaRegister,
                  almRegister,
                  almKantor,
                  tlpRegister,
                  noKtpRegister,
                  noKKRegister,
                  namaPjmRegister,
                  noKtpPjmRegister,
                  linkMapsRegister,
                  linkMapsRegister2,
                  linkMapsRegister3,
                  namaRefRegister,
                  almRefRegister,
                  tlpRefRegister,
                  kodeMarketing: tempMarketing.data._id,
                  kodeSurveyor: tempSurveyor.data._id,
                  kodePekerjaan: tempPekerjaan.data._id,
                  ketPekerjaan,
                  kodeKecamatan: tempKecamatan.data._id,
                  kodeLeasing: tempLeasing.data._id,
                  tglInput: current_date,
                  jamInput: current_time,
                  userInput: user.username,
                  kodeCabang: user.cabang._id,
                  id: user._id,
                  token: user.token,
                },
                daftarStokData: {
                  idDaftarStok: tempStok.data._id,
                  noJual,
                  tanggalJual: tglJual,
                  id: user._id,
                  token: user.token,
                },
                kodeCabang: user.cabang._id,
                id: user._id,
                token: user.token,
              }
            );
          } else {
            await axios.post(`${tempUrl}/saveJualCustomerUpdateDaftarStok`, {
              jualData: {
                COA: tempCOA.data._id,
                noRegister,
                namaRegister,
                almRegister,
                almKantor,
                tlpRegister,
                noKtpRegister,
                noKKRegister,
                namaPjmRegister,
                noKtpPjmRegister,
                linkMapsRegister,
                linkMapsRegister2,
                linkMapsRegister3,
                namaRefRegister,
                almRefRegister,
                tlpRefRegister,
                kodeMarketing: tempMarketing.data._id,
                kodeSurveyor: tempSurveyor.data._id,
                kodeSurveyorAsli: tempSurveyor.data._id,
                kodePekerjaan: tempPekerjaan.data._id,
                ketPekerjaan,
                kodeKecamatan: tempKecamatan.data._id,
                kodeLeasing: tempLeasing.data._id,
                noRangka,
                noMesin,
                nopol,
                tipe,
                namaWarna,
                tahun,
                namaStnk,
                noBpkb,
                hargaTunai: hargaTunai.replace(/,/g, ""),
                uangMuka: uangMuka.replace(/,/g, ""),
                sisaPiutang,
                angPerBulan: angPerBulan.replace(/,/g, ""),
                tenor,
                bunga,
                jumlahPiutang,
                noJual,
                noKwitansi,
                tanggalJual: tglJual,
                jenisJual,
                tglAng,
                tglAngAkhir: tempTglAngAkhir,
                angModal: sisaPiutang / tenor,
                angBunga: angPerBulan.replace(/,/g, "") - sisaPiutang / tenor,
                jenisBeli: "BEKAS",
                userInput: user.username,
                tglInput: current_date,
                jamInput: current_time,
                userInput: user.username,
                kodeCabang: user.cabang._id,
                id: user._id,
                token: user.token,
              },
              customerData: {
                noRegister,
                namaRegister,
                almRegister,
                almKantor,
                tlpRegister,
                noKtpRegister,
                noKKRegister,
                namaPjmRegister,
                noKtpPjmRegister,
                linkMapsRegister,
                linkMapsRegister2,
                linkMapsRegister3,
                namaRefRegister,
                almRefRegister,
                tlpRefRegister,
                kodeMarketing: tempMarketing.data._id,
                kodeSurveyor: tempSurveyor.data._id,
                kodePekerjaan: tempPekerjaan.data._id,
                ketPekerjaan,
                kodeKecamatan: tempKecamatan.data._id,
                kodeLeasing: tempLeasing.data._id,
                tglInput: current_date,
                jamInput: current_time,
                userInput: user.username,
                kodeCabang: user.cabang._id,
                id: user._id,
                token: user.token,
              },
              daftarStokData: {
                idDaftarStok: tempStok.data._id,
                noJual,
                tanggalJual: tglJual,
                id: user._id,
                token: user.token,
              },
              kodeCabang: user.cabang._id,
              id: user._id,
              token: user.token,
            });
          }
          // Update No Kwitansi User
          let updateUser = await axios.post(
            `${tempUrl}/updateUserThenLogin/${user._id}`,
            {
              noTerakhir,
              id: user._id,
              token: user.token,
            }
          );
          const findSetting = await axios.post(`${tempUrl}/lastSetting`, {
            kodeCabang: updateUser.data.details.cabang._id,
            id: updateUser.data.details._id,
            token: updateUser.data.details.token,
          });
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: updateUser.data.details,
            setting: findSetting.data,
          });
          // setLoading(false);
          navigate("/jual");
        } catch (error) {
          alert(error);
        }
      }
    }
    setValidated(true);
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Penjualan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Tambah Jual Bekas
      </Typography>
      <Typography sx={subTitleText}>
        Periode : {user.periode.namaPeriode}
      </Typography>
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Data No Jual Sama`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`No Jual ${noJual} sudah ada, ganti No Jual!`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Divider sx={dividerStyle} />
      <Form noValidate validated={validated} onSubmit={saveJual}>
        <Paper sx={contentContainer} elevation={12}>
          {/* Data Penjualan */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA PENJUALAN
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Jual <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="5">
                        <Form.Control
                          value={noJual}
                          required
                          onChange={(e) =>
                            setNoJual(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          No. Jual harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                      <Col sm="3">
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={() => handleClickOpenJual()}
                        >
                          <SearchIcon />
                        </Button>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Kwitansi :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noKwitansi} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal Jual <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          minDate={minDate}
                          maxDate={new Date(user.periode.periodeAkhir)}
                          selected={inputTglJual}
                          onChange={(e) => {
                            setInputTglJual(e);
                            setInputTglAng(addOneMonths(new Date(e)));
                          }}
                          customInput={
                            <TextField
                              error={error && inputTglJual === null && true}
                              helperText={
                                error &&
                                inputTglJual === null &&
                                "Tanggal Jual harus diisi!"
                              }
                              sx={{ width: "100%" }}
                              size="small"
                            />
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Jenis Jual <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          required
                          onChange={(e) => {
                            if (e.target.value === "TUNAI") {
                              setInputTglAng(null);
                              setTglAngAkhir(null);
                              setTglAngAkhirFormatted(null);
                              setTenor(0);
                              setAngPerBulan("0");
                              setJumlahPiutang(0);
                            }
                            setJenisJual(e.target.value);
                          }}
                        >
                          {jenisJualOption.map((jenisJualOption) => {
                            return (
                              <option value={jenisJualOption.label}>
                                {jenisJualOption.label}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Jenis Jual harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        COA <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kodeCOA}
                          required
                          placeholder="Pilih..."
                          onClick={() => handleClickOpenCOA()}
                        />
                        <Form.Control.Feedback type="invalid">
                          COA harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Customer */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA CUSTOMER
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Register <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noRegister}
                          required
                          placeholder="Pilih..."
                          onClick={() => handleClickOpenRegister()}
                        />
                        <Form.Control.Feedback type="invalid">
                          Kode Register harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={namaRegister}
                          onChange={(e) =>
                            setNamaRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Rumah :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={almRegister}
                          onChange={(e) =>
                            setAlmRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat Kantor :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={almKantor}
                          onChange={(e) =>
                            setAlmKantor(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Telepon Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tlpRegister}
                          onChange={(e) =>
                            setTlpRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KTP Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noKtpRegister}
                          onChange={(e) =>
                            setNoKtpRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. KK Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noKKRegister}
                          onChange={(e) =>
                            setNoKKRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Nama Penjamin Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={namaPjmRegister}
                          onChange={(e) =>
                            setNamaPjmRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        No. KTP Penjamin Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noKtpPjmRegister}
                          onChange={(e) =>
                            setNoKtpPjmRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Link Maps Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={linkMapsRegister}
                          onChange={(e) => setLinkMapsRegister(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Link Maps Register 2 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={linkMapsRegister2}
                          onChange={(e) => setLinkMapsRegister2(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Link Maps Register 3 :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={linkMapsRegister3}
                          onChange={(e) => setLinkMapsRegister3(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Nama Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={namaRefRegister}
                          onChange={(e) =>
                            setNamaRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Alamat Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={almRefRegister}
                          onChange={(e) =>
                            setAlmRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Telepon Ref. Register :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={tlpRefRegister}
                          onChange={(e) =>
                            setTlpRefRegister(e.target.value.toUpperCase())
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Marketing <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={marketingOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error && kodeMarketing.length === 0 && true
                              }
                              helperText={
                                error &&
                                kodeMarketing.length === 0 &&
                                "Kode Marketing harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) =>
                            setKodeMarketing(value.split(" ", 1)[0])
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Surveyor <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={surveyorOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && kodeSurveyor.length === 0 && true}
                              helperText={
                                error &&
                                kodeSurveyor.length === 0 &&
                                "Kode Surveyor harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) =>
                            setKodeSurveyor(value.split(" ", 1)[0])
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Pekerjaan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={pekerjaanOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error && kodePekerjaan.length === 0 && true
                              }
                              helperText={
                                error &&
                                kodePekerjaan.length === 0 &&
                                "Kode Pekerjaan harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodePekerjaan(value)}
                          value={{ label: kodePekerjaan }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}></Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={ketPekerjaan}
                          onChange={(e) => {
                            setKetPekerjaan(e.target.value.toUpperCase());
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Kecamatan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={kecamatanOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={
                                error && kodeKecamatan.length === 0 && true
                              }
                              helperText={
                                error &&
                                kodeKecamatan.length === 0 &&
                                "Kode Kecamatan harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodeKecamatan(value)}
                          value={{ label: kodeKecamatan }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Leasing <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={leasingOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && kodeLeasing.length === 0 && true}
                              helperText={
                                error &&
                                kodeLeasing.length === 0 &&
                                "Kode Leasing harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) =>
                            setKodeLeasing(value.split(" ", 1)[0])
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Motor */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA MOTOR
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nopol <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={nopolOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && nopol.length === 0 && true}
                              helperText={
                                error &&
                                nopol.length === 0 &&
                                "Nopol harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            if (value) {
                              getStoksByNopol(value);
                            } else {
                              setNoRangka("");
                              setNoMesin("");
                              setTipe("");
                              setNamaWarna("");
                              setTahun("");
                              setNamaStnk("");
                              setNoBpkb("");
                              setNopol("");
                            }
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Rangka :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noRangka} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Mesin :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noMesin} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama STNK :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaStnk} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tipe :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tipe} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama Warna :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaWarna} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tahun Perakitan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tahun} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Bpkb :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noBpkb} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Rincian Harga (Input) */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              RINCIAN HARGA
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Tanggal Angsuran I <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          minDate={addOneMonths(inputTglJual)}
                          maxDate={
                            user.tipeUser !== "MGR" &&
                            addDays(
                              addOneMonths(inputTglJual),
                              setting.maxMundurAngsuranSetting
                            )
                          }
                          selected={inputTglAng}
                          onChange={(e) => setInputTglAng(e)}
                          readOnly={jenisJual === "TUNAI" && true}
                          customInput={
                            <TextField
                              error={
                                error &&
                                inputTglAng === null &&
                                jenisJual === "KREDIT" &&
                                true
                              }
                              helperText={
                                error &&
                                inputTglAng === null &&
                                jenisJual === "KREDIT" &&
                                "Tanggal Angsuran I harus diisi!"
                              }
                              sx={{
                                backgroundColor:
                                  jenisJual === "TUNAI" && Colors.grey400,
                                width: "100%",
                              }}
                              size="small"
                            />
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Harga Tunai <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          //type="number"
                          value={hargaTunai}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setHargaTunai(tempNum);
                            //setHargaTunai(e.target.value);
                            let tempSisaPiutang =
                              parseInt(tempNum.replace(/,/g, "")) - uangMuka;
                            setSisaPiutang(tempSisaPiutang);
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Harga Tunai harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Uang Muka <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          //type="number"
                          value={uangMuka}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setUangMuka(tempNum);
                            //setUangMuka(e.target.value);
                            let tempSisaPiutang =
                              parseInt(hargaTunai.replace(/,/g, "")) -
                              parseInt(tempNum.replace(/,/g, ""));
                            setSisaPiutang(tempSisaPiutang);
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Uang Muka harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Sisa Piutang :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={sisaPiutang.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRightSmall}>
                        Tanggal Angsuran Akhir :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tglAngAkhirFormatted} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Angsuran/Bulan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          //type="number"
                          value={angPerBulan}
                          onChange={(e) => {
                            let tempNum;
                            let isNumNan = isNaN(
                              parseInt(e.target.value.replace(/,/g, ""), 10)
                            );
                            if (isNumNan) {
                              tempNum = "";
                            } else {
                              tempNum = parseInt(
                                e.target.value.replace(/,/g, ""),
                                10
                              ).toLocaleString("en-US");
                            }
                            setAngPerBulan(tempNum);
                            //setAngPerBulan(e.target.value);
                            let tempJumlahPiutang =
                              parseInt(tempNum.replace(/,/g, "")) * tenor;
                            setJumlahPiutang(tempJumlahPiutang);
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Ang/Bulan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tenor <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          value={tenor}
                          onChange={(e, value) => {
                            countDateDuration(e);
                            setTenor(e.target.value);
                            let tempJumlahPiutang =
                              parseInt(angPerBulan.replace(/,/g, "")) *
                              e.target.value;
                            setJumlahPiutang(tempJumlahPiutang);
                          }}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Tenor harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Total Piutang :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={jumlahPiutang.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/jual")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Paper>
      </Form>
      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openRegister}
        onClose={handleCloseRegister}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Pembeli`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Register
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Tanggal
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Customer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {registers
                    .filter((val) => {
                      if (searchTermRegister === "") {
                        return val;
                      } else if (
                        val.noRegister
                          .toUpperCase()
                          .includes(searchTermRegister.toUpperCase()) ||
                        val.tanggalRegister
                          .toUpperCase()
                          .includes(searchTermRegister.toUpperCase()) ||
                        val.namaRegister
                          .toUpperCase()
                          .includes(searchTermRegister.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setNoRegister(user.noRegister);
                          setNamaRegister(user.namaRegister);
                          setAlmRegister(user.almRegister);
                          setAlmKantor(user.almRegister);
                          setTlpRegister(user.tlpRegister);
                          setNoKtpRegister(user.noKtpRegister);
                          setNoKKRegister(user.noKKRegister);
                          setNamaPjmRegister(user.namaPjmRegister);
                          setNoKtpPjmRegister(user.noKtpPjmRegister);
                          setNamaRefRegister(user.namaRefRegister);
                          setAlmRefRegister(user.almRefRegister);
                          setTlpRefRegister(user.tlpRefRegister);

                          setKodePekerjaan(
                            `${user.pekerjaanId.kodePekerjaan} - ${user.pekerjaanId.namaPekerjaan}`
                          );
                          setKodeKecamatan(
                            `${user.kecamatanId.kodeKecamatan} - ${user.kecamatanId.namaKecamatan}`
                          );
                          handleCloseRegister();
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.noRegister}
                        </TableCell>
                        <TableCell>{user.tanggalRegister}</TableCell>
                        <TableCell>{user.namaRegister}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCOA}
        onClose={handleCloseCOA}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data COA`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermCOA} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kode COA
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsCOA
                    .filter((val) => {
                      if (searchTermCOA === "") {
                        return val;
                      } else if (
                        val.kodeCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase()) ||
                        val.namaCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setKodeCOA(user.kodeCOA);
                          handleCloseCOA();
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.kodeCOA}
                        </TableCell>
                        <TableCell>{user.namaCOA}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openJual}
        onClose={handleCloseJual}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Jual Terakhir`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermJual} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Jual
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Tanggal Jual
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Customer
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Leasing
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Tipe</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsJual
                    .filter((val) => {
                      if (searchTermJual === "") {
                        return val;
                      } else if (
                        val.noJual
                          .toUpperCase()
                          .includes(searchTermJual.toUpperCase()) ||
                        val.tanggalJualFormatted
                          .toUpperCase()
                          .includes(searchTermJual.toUpperCase()) ||
                        val.namaRegister
                          .toUpperCase()
                          .includes(searchTermJual.toUpperCase()) ||
                        val.kodeLeasing.kodeLeasing
                          .toUpperCase()
                          .includes(searchTermJual.toUpperCase()) ||
                        val.kodeLeasing.namaLeasing
                          .toUpperCase()
                          .includes(searchTermJual.toUpperCase()) ||
                        val.tipe
                          .toUpperCase()
                          .includes(searchTermJual.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setNoJual(user.noJual);
                          handleCloseJual();
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.noJual}
                        </TableCell>
                        <TableCell>{user.tanggalJualFormatted}</TableCell>
                        <TableCell>{user.namaRegister}</TableCell>
                        <TableCell>
                          {user.kodeLeasing.kodeLeasing} -{" "}
                          {user.kodeLeasing.namaLeasing}
                        </TableCell>
                        <TableCell>{user.tipe}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TambahJualBekas;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
